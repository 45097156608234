import { lazy, StrictMode, Suspense } from 'react';
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from 'react-router-dom';

// Импортируем компоненты страниц  
const Page = lazy(() => import('./pages/Page'));
const LoginPage = lazy(() => import('./pages/LoginPage'));
const OnbordingPage = lazy(() => import('./pages/OnbordingPage'));

const App = () => {
  return (
    <BrowserRouter>
      {/* fallback={<Loader isLoading isPage></Loader>} */}
      <Suspense >
        <Routes>
          <Route path="/" element={<Page />} />
          <Route path="/:space_id/:page_id/" element={<Page />} />
          <Route path="/onbording/" element={< OnbordingPage />} />
          <Route path="/account/login/" element={< LoginPage />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <StrictMode><App />
  </StrictMode>

);
